<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>Кабинет</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-list dense>
        <!-- <v-subheader inset> Кабинет </v-subheader> -->
        <v-list-item-group color="primary">
          <v-list-item :to="{ name: 'CabinetSettings' }" class="mt-1" link>
            <v-list-item-icon>
              <v-icon color="grey darken-1"
                >$vuetify.icons.account_settings</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Настройки профиля</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CabinetSettingsAuthor' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1"
                >$vuetify.icons.account_settings</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Анкета автора</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CabinetBooks' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1"
                >$vuetify.icons.book_open_page_variant</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Произведения</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CabinetSeries' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Циклы произведений</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CabinetBlogs' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1"
                >$vuetify.icons.book_open_page_variant</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Блоги</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import CabinetList from "@/components/cabinet/CabinetList";
export default {
  components: {
    // CabinetList,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
};
</script>
